.four04--container {
    display: flex;
    align-items: center;
    padding: 0 5%;
    height: calc(100vh - 55px);
    max-width: 1000px;
    margin: 0 auto;
    .left {
        width: 65%;
    }
    .right {
        width: 35%;
        padding-left: 30px;
    }
}
