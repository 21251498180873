* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html,
body {
    // min-height: 100%;
    font-size: size(16);
    font-family: 'Inter';
    font-variant-numeric: tabular-nums;
    padding: 0;
    margin: 0;
}
/* Added this to remove the up down arrow around TextField type number*/
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

a {
    cursor: pointer;
}

img {
    max-width: 100%;
    vertical-align: middle;
}

/* width */
::-webkit-scrollbar {
    height: 8px;
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #c4c4c8;
    border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #747478;
}

@for $i from 0 through 20 {
    .p {
        &--#{$i * 5} {
            padding: size($i * 5);
        }
    }

    .pt {
        &--#{$i * 5} {
            padding-top: size($i * 5);
        }
    }

    .pr {
        &--#{$i * 5} {
            padding-right: size($i * 5);
        }
    }

    .pb {
        &--#{$i * 5} {
            padding-bottom: size($i * 5);
        }
    }

    .pl {
        &--#{$i * 5} {
            padding-left: size($i * 5);
        }
    }

    .m {
        &--#{$i * 5} {
            margin: size($i * 5);
        }
    }

    .mt {
        &--#{$i * 5} {
            margin-top: size($i * 5);
        }
    }

    .mr {
        &--#{$i * 5} {
            margin-right: size($i * 5);
        }
    }

    .mb {
        &--#{$i * 5} {
            margin-bottom: size($i * 5);
        }
    }

    .ml {
        &--#{$i * 5} {
            margin-left: size($i * 5) !important;
        }
    }
}

@for $i from 0 through 54 {
    .fs--#{$i} {
        font-size: size($i);
    }
}

.flex {
    display: flex;

    &.expand,
    &.full {
        flex: 1;
    }

    &--jcsb {
        justify-content: space-between;
    }

    &--jcse {
        justify-content: space-evenly;
    }

    &--jcc {
        justify-content: center;
    }

    &--jcfe {
        justify-content: flex-end;
    }
    &--jcfs {
        justify-content: flex-start;
    }

    &--aic {
        align-items: center;
    }

    &--aife {
        align-items: flex-end;
    }

    &--aifs {
        align-items: flex-start;
    }

    &--ais {
        align-items: stretch;
    }

    &--col {
        flex-direction: column;
    }
    &--col-reverse {
        flex-direction: column-reverse;
    }
    &--row {
        flex-direction: row;
    }
    &--row-reverse {
        flex-direction: row-reverse;
    }

    &--nowrap {
        flex-wrap: nowrap;
    }

    &--wrap {
        flex-wrap: wrap;
    }

    &--fdc {
        flex-direction: column;
    }
}


@for $i from 0 through 54 {
    .row-gap-#{$i} {
        row-gap: #{$i}px;
    }
}
@for $i from 0 through 54 {
    .col-gap-#{$i} {
        column-gap: #{$i}px;
    }
}

.capital {
    &--first-letter {
        text-transform: lowercase;
        &::first-letter {
            text-transform: uppercase;
        }
    }
}


@for $size from 1 through 50 {
    .row-gap--#{$size} {
        row-gap: #{$size}px;
    }
    .column-gap--#{$size} {
        column-gap: #{$size}px;
    }
  }
  



.fullWidth {
    width: 100%;
}

.menuOptionIcon {
    min-width: 25px;
    text-align: center;
}

.Toastify__toast-container {
    max-width: 450px !important;
    width: auto !important;
}

.Toastify__toast {
    padding: 0px 10px !important;
    min-height: max-content !important;
    background-color: #e1f0ff !important;
    border-radius: 50px !important;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.16) !important;
}

.Toastify__toast-body {
    margin: 5px 0 !important;
    height: max-content !important;
}

.disabled-opacity {
    pointer-events: none;
    opacity: 0.6;
}

.numHeaderNumber {
    font-size: 25px !important;
    opacity: 0.5 !important;
    right: calc(100% - 25px);
    color: #007aff !important;
    min-width: max-content;
}
.numHeader {
    font-weight: 500 !important;
    margin-left: 38px !important;
}

.item-type--indicator {
    position: absolute;
    z-index: 2;
    left: -10px;
    top: -3px;
    width: 10px;
    height: 10px;
    background-color: currentColor;
    border-radius: 50% !important;
}

.no-arrow {
    .MuiAccordionSummary-content {
        cursor: default;
    }
    .MuiAccordionSummary-expandIconWrapper {
        display: none;
    }
}

.item--or--spec {
    .MuiFormControl-root {
        .or--input {
            .MuiOutlinedInput-notchedOutline {
                border-radius: 0;
                // border-top-width: 0;
            }
        }
        &:nth-child(1) {
            .or--input {
                .MuiOutlinedInput-notchedOutline {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                    // border-top-width: 1px;
                }
            }
        }
        &:nth-last-child(1) {
            .or--input {
                .MuiOutlinedInput-notchedOutline {
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                }
            }
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.green-heading {
    font-size: size(36);
    font-weight: 500;
    line-height: 1.33;
    color: #38b000;
    font-style: normal;
    letter-spacing: -0.02em;
}

.page-bottom-fixed-bar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - 4.2%);
    background: white;
    padding: 16px 20px;
    z-index: 10;

    @media screen and (max-width: 1199px) {
        width: calc(100% - 8.4%);
    }
}

.MuiSwitch-thumb {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.26) !important;
}

.MuiInputBase-input:placeholder-shown:not(:hover)
    ~ .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23) !important;
}
