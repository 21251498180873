$primary :rgba(0, 122, 255,1);
$primary-off :rgba(0, 122, 255,0);
$error : rgba(255, 59, 48,0.5);
$error-off : rgba(255, 59, 48,0);
$secondary : rgba(196, 196, 200,1);
$secondary-off : rgba(196, 196, 200,0);


.ripple::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(233, 90, 90);
    border-radius: 50%;
    z-index: -1;
    transform: translate(3px, 0);
    animation: ripple 2s ease-out infinite;
    top: 3px;
    left: 5px;
    z-index: 2;
}

@keyframes ripple {
    from {
        opacity: 1;
        transform: scale(0);
    }
    50% {
        opacity: 0;
        transform: scale(1.5);
    }
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}


.pulse-primary {
  animation: pulse-animation-primary 2s infinite;
}

@keyframes pulse-animation-primary {
  0% {
    box-shadow: 0 0 0 0px $primary;
  }
  100% {
    box-shadow: 0 0 0 7px $primary-off;
  }
  
}

.pulse-error {
  animation: pulse-animation-error 2s infinite;
}

@keyframes pulse-animation-error {
  0% {
    box-shadow: 0 0 0 0px $error;
  }
  100% {
    box-shadow: 0 0 0 10px $error-off;
  }
  
}