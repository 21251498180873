$icomoon-font-family: 'FactWise' !default;
$icomoon-font-path: '../../Assets/Fonts' !default;

$fw--heat-map: '\e915';
$fw--add-edit-vendor: '\e900';
$fw--admin: '\e901';
$fw--analytics: '\e902';
$fw--approval-log: '\e903';
$fw--bid-history: '\e904';
$fw--decide-later: '\e905';
$fw--finance: '\e906';
$fw--good-receipt: '\e907';
$fw--internal-notes: '\e908';
$fw--invoice: '\e909';
$fw--negotiations: '\e90a';
$fw--purchase-order-create: '\e90b';
$fw--purchase-order-view: '\e90c';
$fw--quality-check: '\e90d';
$fw--review: '\e90e';
$fw--template: '\e90f';
$fw--rejection-form: '\e910';
$fw--grn-add: '\e911';
$fw--grn-done: '\e912';
$fw--grn: '\e913';
$fw--invoice-add: '\e914';
