@media print {
    .printPO {
        padding: 20px;
        size: A4 portrait;
    }
    .pageBreak {
        break-inside: avoid;
    }
    body {
        zoom: 100%;
    }
}

.hideBg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(12, 12, 16, 0.8);
    z-index: 9999;
}
