/* Customize the font and color of the date text */
.rdrDayNumber {
    font-weight: 500;
}

.rdrWeekDay {
    font-family: 'Inter', sans-serif; 
    font-weight: 600;
    color: rgb(18, 18, 18); 

}
/* 
.rdrDateDisplayWrapper{
    width: 300px !important;
}

.rdrMonthAndYearWrapper{
    width: 300px !important;
}

.rdrMonths .rdrMonthsVertical{
    width: 300px !important;
}

.rdrDays{
    width: 300px !important;
} */