.ag-theme-alpine-project {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    position: relative;

    .hide-select-all {
        .ag-checkbox {
            display: none;
        }
    }

    .no-border {
        .ag-root-wrapper {
            border: none;
            border-radius: 0;
        }
    }

    .gr-border {
        .ag-root-wrapper {
            border: 1px solid #c4c4c8;
            border-radius: 0 0 12px 12px;
            border-top: 0;
        }
    }

    .ag-root-wrapper {
        margin-bottom: 20px;
        width: 100%;
        // height: 600px;
        border: 1px solid rgba(224, 224, 224, 1);
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        overflow: hidden;
        &-body {
            &.ag-layout-normal {
                height: inherit;
            }
        }
    }

    .ag-row {
        &:hover {
            background-color: var(
                --ag-row-hover-color,
                rgba(33, 150, 243, 0.1)
            );
        }
        font-size: 14px;
        border-bottom: 1px solid #dde2eb;
        // &-odd:not(.ag-row-hover) {
        //     background-color: #fff;
        // }
        &-odd.ag-row-hover:not(.disabled) {
            background-color: var(
                --ag-row-hover-color,
                rgba(33, 150, 243, 0.1)
            );
        }
        &-even.ag-row-hover:not(.disabled) {
            background-color: var(
                --ag-row-hover-color,
                rgba(33, 150, 243, 0.1)
            );
        }
        // &-odd {
        //     &.disabled {
        //         &.ag-row-hover:not(.ag-row-hover) {
        //             background-color: #f0f0f2;
        //         }
        //     }
        //     &.no-bg {
        //         background-color: #fff;
        //     }
        // }
        // &.disabled {
        //     cursor: not-allowed !important;
        // }
        &-selected {
            &:not(.ag-row-hover) {
                // background-color: transparent;
                background-color: var(
                    --ag-row-hover-color,
                    rgba(3, 111, 198, 0.295)
                ) !important;
            }
            &:not(.ag-row-even) {
                // background-color: #f0f0f2;
                background-color: var(
                    --ag-row-hover-color,
                    rgba(3, 111, 198, 0.295)
                ) !important;
            }
            &.ag-row-hover {
                background-color: var(
                    --ag-row-hover-color,
                    rgba(3, 111, 198, 0.295)
                ) !important;
            }
            background-color: var(
                --ag-row-hover-color,
                rgba(3, 111, 198, 0.295)
            ) !important;
        }

        &.pending {
            .gr--options {
                display: none;
            }
        }

        &.hide-checkbox {
            .ag-selection-checkbox {
                display: none;
            }
        }

        &.no-row-border {
            border-color: white;
        }
        &.disable-checkbox {
            // &.ag-row {
            //     opacity: 0.5;
            // }
            .ag-selection-checkbox {
                cursor: not-allowed;
                opacity: 0.2;
                height: auto;

                input {
                    pointer-events: none;
                }
            }
        }
        .ag-selection-checkbox {
            input {
                cursor: pointer;
            }
        }
    }

    .ag-header {
        position: sticky;
        cursor: pointer;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        &-cell {
            padding-left: 18px;
            padding-right: 18px;
            &-text {
                color: black;
                font-size: 16px;
                font-family: 'Inter';
                line-height: 1.5em;
                letter-spacing: -0.011px;
                font-weight: 300;
                text-overflow: ellipsis;
                // font-weight: 500;
                // font-style: normal;
            }

            // &:last-child {
            //     &::after {
            //         display: none;
            //     }
            // }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                height: 16px;
                width: 1px;
                background-color: #9d9a9a;
            }
            &.center {
                .ag-header-cell-label {
                    justify-content: center;
                }
            }
        }
        background-color: #f6f5f5;
    }

    .ag-header .ag-header-cell-moving {
        background-color: #f6f5f5;
    }

    .ag-cell {
        display: inline-flex;
        align-items: center;
        font-size: 16px;
        height: 100%;
        padding-left: 17px;
        padding-right: 17px;

        .ag-cell-edit-wrapper {
            height: 25px;
        }

        &-wrapper {
            width: 100%;
            .ag-cell-value {
                width: 100%;
            }
        }
    }

    &.pointer {
        .ag-row {
            cursor: pointer;
        }
    }
    .ag-paging-panel {
        border-top: 1px solid rgba(224, 224, 224, 1);
    }

    .grn-rows {
        .ag-row {
            // ont-family: 'Roboto Mono';
            &:not(:hover) {
                background-color: #fff;
            }
            &:last-of-type {
                .addIcon {
                    visibility: visible;
                    pointer-events: all;
                }
            }
            .addIcon {
                visibility: hidden;
                pointer-events: none;
            }
        }
        .ag-cell {
            display: flex;
            align-items: flex-start;
            padding-top: 10px;
            padding-bottom: 10px;

            .label-div {
                line-height: 1;
            }
        }
    }

    .gr-middle-page {
        .ag-row.ag-row-pinned {
            background-color: #94c7ff;
        }
    }
}

// A theme with no border radius except that everything is same as the above them
.ag-theme-alpine-no-border-radius {
    .ag-root-wrapper {
        border-radius: 0px;
    }
}
// theme for matrix view
.ag-theme-alphine-matrix-view {
    .ag-root-wrapper {
        border-bottom: none !important;
    }
    .ag-cell {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ag-header-cell {
        font-size: 12px;
        padding-left: 11px;
        padding-right: 11px;
        &.right {
            .ag-header-cell-label {
                justify-content: flex-end;
            }
        }
    }
    .MuiTypography-root {
        font-size: inherit;
    }
    .ag-center-cols-container,
    .ag-floating-bottom-container {
        .ag-cell {
            padding: 0px;
        }
    }
    // .ag-center-cols-container {
    //     .ag-row .ag-cell {
    //         border-right: 2px solid rgba(0, 0, 0, 0.1);
    //         // box-shadow: inset rgba(0, 0, 0, 0.15) 1px 0px 0px;
    //     }
    // }
    .ag-row-pinned {
        border-bottom-color: transparent;
    }

    // .ag-header-container {
    //     .ag-header-cell {
    //         padding-left: 11px;
    //         padding-right: 11px;
    //     }
    // }
}


.ag-theme-cost-comparison-view {
    .ag-root-wrapper {
        border-bottom: none !important;
    }
    .ag-cell {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ag-header-cell {
        font-size: 12px;
        padding-left: 11px;
        padding-right: 11px;
        &.right {
            .ag-header-cell-label {
                justify-content: flex-end;
            }
        }
        .ag-header-cell-text {
            font-size: 12px;
        }
    }
    .MuiTypography-root {
        font-size: 12px;
    }
    // .ag-center-cols-container,
    // .ag-floating-bottom-container {
    //     .ag-cell {
    //         padding: 0px;
    //     }
    // }
    // .ag-center-cols-container {
    //     .ag-row .ag-cell {
    //         border-right: 2px solid rgba(0, 0, 0, 0.1);
    //         // box-shadow: inset rgba(0, 0, 0, 0.15) 1px 0px 0px;
    //     }
    // }
    .ag-row-pinned {
        border-bottom-color: transparent;
    }

    // .ag-header-container {
    //     .ag-header-cell {
    //         padding-left: 11px;
    //         padding-right: 11px;
    //     }
    // }
}
.ag-theme-database-view {
    .ag-cell {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ag-header-cell {
        font-size: 12px;
        padding-left: 11px;
        padding-right: 11px;
    }
    .MuiTypography-root {
        font-size: inherit;
    }
    .ag-pinned-left-header {
        border-right: 2px solid #cacdd2;
    }
    .ag-pinned-left-cols-container {
        border-right: 2px solid #cacdd2;
    }
    .ag-row-selected {
        background-color: var(
            --ag-row-hover-color,
            rgba(3, 111, 198, 0.295)
        ) !important;
    }
}

.ag-theme-comparison-view {
    .ag-cell {
        font-size: 12px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .ag-header-cell {
        font-size: 12px;
        padding-left: 11px;
        padding-right: 11px;
    }
    .MuiTypography-root {
        font-size: inherit;
    }
}

.ag-cell-label-container {
    &.header-bar {
        &.blue {
            border-bottom: 3px solid #007aff;
        }
        &.green {
            border-bottom: 3px solid #38b000;
        }
    }
}

.showIconOnRowHover .hoverIcon {
    opacity: 0;
}

.showIconOnRowHover:hover .hoverIcon {
    opacity: 1;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
    padding: 0px !important;
}
