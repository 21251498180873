@import './iconVariables';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lrbuec');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?lrbuec#iefix')
            format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?lrbuec')
            format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?lrbuec')
            format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?lrbuec##{$icomoon-font-family}')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='fw--'],
[class*=' fw--'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &::before {
        vertical-align: middle;
    }
}

.fw--heat-map {
    &:before {
        content: $fw--heat-map;
    }
}
.fw--add-edit-vendor {
    &:before {
        content: $fw--add-edit-vendor;
    }
}
.fw--admin {
    &:before {
        content: $fw--admin;
    }
}
.fw--analytics {
    &:before {
        content: $fw--analytics;
    }
}
.fw--approval-log {
    &:before {
        content: $fw--approval-log;
    }
}
.fw--bid-history {
    &:before {
        content: $fw--bid-history;
    }
}
.fw--decide-later {
    &:before {
        content: $fw--decide-later;
    }
}
.fw--finance {
    &:before {
        content: $fw--finance;
    }
}
.fw--good-receipt {
    &:before {
        content: $fw--good-receipt;
    }
}
.fw--internal-notes {
    &:before {
        content: $fw--internal-notes;
    }
}
.fw--invoice {
    &:before {
        content: $fw--invoice;
    }
}
.fw--negotiations {
    &:before {
        content: $fw--negotiations;
    }
}
.fw--purchase-order-create {
    &:before {
        content: $fw--purchase-order-create;
    }
}
.fw--purchase-order-view {
    &:before {
        content: $fw--purchase-order-view;
    }
}
.fw--quality-check {
    &:before {
        content: $fw--quality-check;
    }
}
.fw--review {
    &:before {
        content: $fw--review;
    }
}
.fw--template {
    &:before {
        content: $fw--template;
    }
}
.fw--rejection-form {
    &:before {
        content: $fw--rejection-form;
    }
}
.fw--grn-add {
    &:before {
        content: $fw--grn-add;
    }
}
.fw--grn-done {
    &:before {
        content: $fw--grn-done;
    }
}
.fw--grn {
    &:before {
        content: $fw--grn;
    }
}
.fw--invoice-add {
    &:before {
        content: $fw--invoice-add;
    }
}
